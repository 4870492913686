/* src/components/Contact.css */

.contact-container {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
  margin-top: 20px;
}

.contact-form {
  max-width: 500px;
  margin: 20px auto;
}

.contact-form .form-group {
  margin-bottom: 15px;
}

.contact-form .btn {
  width: 100%;
}
