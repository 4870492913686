/* Style for navbar */
.navbar {
  background-color: #007bff; /* Brighter blue background */
  padding: 1.5rem 1rem; /* Adjust the padding as needed */
}

/* Ensure the Navbar items align properly */
.navbar-nav {
  flex-direction: row; /* Ensure items are aligned horizontally */
  margin: 0; /* Remove any margin that might affect alignment */
  padding: 0; /* Remove any padding that might affect alignment */
}

/* Style for the logo */
.navbar-logo {
  width: 40px; /* Ensure this matches the size you set in inline styles */
  height: 40px; /* Ensure this matches the size you set in inline styles */
  margin-right: 10px; /* Spacing between the logo and text */
}

/* Adjust spacing between Nav items */
.navbar-nav .nav-link {
  color: #ffffff !important; /* White text color */
  margin-right: 15px; /* Space between Nav items, adjust as needed */
}

/* Highlight color on hover and for active link */
.navbar-nav .nav-link:hover,
.navbar-nav .nav-item.active .nav-link {
  color: #ffcc00 !important; /* Highlight color on hover and for active link */
  background-color: rgba(
    255,
    255,
    255,
    0.2
  ); /* Light background on hover for better contrast */
}

/* Style for the pronunciation text in the navbar */
.pronunciation {
  font-size: 0.75rem; /* Smaller font size */
  color: rgba(255, 255, 255, 0.6); /* Less prominent color */
  margin-left: 0.5rem; /* Space between the main text and the pronunciation */
}

/* Button alignment and appearance */
.navbar .btn {
  margin-left: auto; /* Ensure the button aligns to the right */
  border-color: #ffffff; /* Ensure the button border matches the theme */
}

.navbar .btn:hover {
  color: #000000;
  background-color: #ffcc00; /* Change background color on hover */
}

/* Ensure responsive behavior */
@media (max-width: 576px) {
  .navbar-nav {
    flex-direction: column; /* Stack items vertically on small screens */
  }
}

/* Style for active menu item */
.nav-link.active {
  background-color: #00A86B; /* REACirc green */
  color: #ffffff !important; /* White text */
  border-radius: 4px;
}

/* Style for hover effect */
.nav-link:hover {
  background-color: #003d80; /* Even darker shade for hover effect */
}
