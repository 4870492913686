.brokerages_bg__hero {
  background-image: url("../assets/img/hero-brokerages.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  color: #fff;
  position: relative; /* Required for pseudo-element positioning */
}
.brokerages_bg__hero:after {
  content: "";
  display: block;
  position: absolute; /* Position absolute to overlay on the hero section */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    34,
    139,
    230,
    0.76
  ); /* Adjusted color with transparency */
  z-index: 1; /* Ensure it's above the hero background but below text */
}

.brokerages_bg__hero .row {
  position: relative; /* Ensures the text is above the pseudo-element */
  z-index: 2; /* Higher than the pseudo-element */
}

.option-icon-brokerages {
  font-size: 3.5rem; /* Reduced size for better mobile view */
  margin-bottom: 1rem;
  z-index: 2;

  padding: 0.75rem; /* Add padding to make the background larger */
  border-radius: 15%; /* Rounded background to make it circular */
  display: flex; /* Center the icon */
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
}
