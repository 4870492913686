.nsc2024_bg__hero {
  background-image: url("../assets/img/hero-nsc2024.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  position: relative; /* Required for pseudo-element positioning */
}


.nsc2024_bg__hero:after {
  content: "";
  display: block;
  position: absolute; /* Position absolute to overlay on the hero section */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    34,
    139,
    230,
    0.76
  ); /* Adjusted color with transparency */
  z-index: 1; /* Ensure it's above the hero background but below text */
}

.nsc2024_bg__hero .row {
  position: relative; /* Ensures the text is above the pseudo-element */
  z-index: 2; /* Higher than the pseudo-element */
}

/* Modal Styles */
.modal-content {
  border-radius: 10px; /* Rounded corners for the modal */
  padding: 20px; /* Padding inside the modal */
  background-color: #ffffff; /* White background for the modal */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for the modal */
}


/* Modal Styles */
.modal-header {
  border-bottom: 1px solid #e9ecef; /* Light border at the bottom of the header */
}

.modal-title {
  font-size: 1.5rem; /* Larger font size for the title */
  color: #333333; /* Dark text color for the title */
}

.close {
  font-size: 1.25rem; /* Larger close button */
  color: #007bff; /* Color of the close button */
}

/* Form Styles */
.form-control {
  border-radius: 5px; /* Rounded corners for form inputs */
  border: 1px solid #ced4da; /* Light border for form inputs */
  padding: 10px; /* Padding inside form inputs */
  margin: 2px;
}
.form-box input {

  margin: 10px;
}
.form-box div.form-select {

  margin: 10px;
}

.form-control:focus {
  border-color: #007bff; /* Blue border on focus */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Blue shadow on focus */
}

.btn--optin {
  color: #000000; /* White text color for the button */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners for the button */
  padding: 10px 20px; /* Padding inside the button */
  font-size: 1rem; /* Font size for the button text */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.btn--optin:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.text-danger {
  color: #dc3545; /* Red color for error messages */
}

/* Modal Intro Paragraph */
.modal-intro {
  font-size: 1rem; /* Font size for introductory text */
  color: #666666; /* Light gray text color */
  margin-bottom: 20px; /* Space below the intro paragraph */
}

/* Style for the file input field */

.file-upload input[type="file"] {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 35px;
  
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background: #ffffff;
}

.file-upload label {
  position: relative;
  top: 0;
  right: 0;
  background-color: #007bff;
  color: #ffffff;
  border: 1px solid #007bff;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.form-group.file-upload input[type="file"]::file-selector-button {
  display: none; /* Hide the default file input button */
}

