.section {
  /* padding: 3rem 0; */
  margin-bottom: 5px;
}

h1 {
  /* display: flex; */
  align-items: center; /* Center vertically with text */
}

.logo {
  height: 40px; /* Adjust the height as needed */
  margin-left: 10px; /* Space between text and logo */
  vertical-align: middle; /* Align with text vertically */
}
/* General Styles */
.intro-description {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.intro-highlight {
  font-size: 1.1rem;
  font-weight: bold;
  color: #007bff;
}

/* Heading Styles */
#hero {
  background-color: #182336;
  color: #fff;
  padding: 3.5rem 0;
  padding-bottom: 3rem;
  z-index: 0;
}

#hero h1 {
  font-size: 3rem;
  line-height: 1.15;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

#hero h2 {
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.45;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.98);
}

#hero h4 {
  font-weight: normal;
  font-size: 0.8rem;
  line-height: 1.5;
  color: var(--light-yellow);
  margin-bottom: 0.75rem;
  border-top: double;
  border-bottom: double;
  display: inline-block;
  padding: 0.25rem 0;
}

.container {
  max-width: 1200px; /* Set a maximum width for the container */
  margin: 10px auto; /* Center the container */
  padding: 0 15px; /* Add padding to the sides of the container */
}

/* Button Styles */
.btn--optin {
  font-size: 1rem;
  background-color: var(--light-yellow);
  color: var(--color--brown);
  padding: 0.6rem 1rem;
  white-space: nowrap;
  margin-top: 0.4rem;
  width: 100%;
  line-height: 1;
  font-weight: bold;
  z-index: 1;
}

.btn--optin:hover {
  background-color: var(--color--brown);
  color: #fff;
}

/* Form Styles */
.signup-form {
  margin-top: 15px;
  z-index: 1;
}

input::placeholder {
  color: #a3a3a3;
  opacity: 0.5;
  z-index: 1;
}

/* List Styles */
.block__list {
  border-radius: 0.25rem;
  border: 2px solid #fad769;
  background-color: #fff;
  padding: 2rem;
  z-index: 1;
}

.block__list ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  width: 95%;
  margin: 0 auto;
}

.icon__check--blue {
  margin-bottom: 2rem;
  text-align: left;
}

.icon__check--blue li {
  padding-left: 2rem;
  margin-bottom: 1rem;
  position: relative;
  font-size: 1rem;
}

.icon__check--blue li:before {
  content: "";
  display: block;
  height: 1.2rem;
  width: 1.2rem;
  position: absolute;
  left: 0;
  top: 0.2rem;
  background-image: url("../assets/img/icon_tick.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.nscLogo {
  height: 140px;
  position: relative;
  top: -10px;
  left: -50px;
}

/* Button Group Styles */
.btn-group {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 1rem;
  z-index: 1;
}
@media only screen and (max-width: 640px) {
  .btn-group {
    flex-direction: column;
  }
}

.option-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 100%;
  padding: 0.8rem;
  text-align: center;
  color: #fff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  position: relative;
  margin-bottom: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.option-button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.option-text-box {
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  height: 160px;
}

.option-text h2 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.option-text p {
  font-size: 0.8rem;
}

.option-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  z-index: 2;
  background-color: hsl(0, 0%, 12%);
  padding: 0.75rem;
  border-radius: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Image Button Backgrounds */
.nsc2024-button {
  background-image: url("../assets/img/hero-nsc2024.jpg");
}

.brokerages-button {
  background-image: url("../assets/img/hero-brokerages.png");
}

.agents-button {
  background-image: url("../assets/img/hero-agent.png");
}

.conferences-button {
  background-image: url("../assets/img/hero-conference.png");
}

.nsc2024-logo {
  background-image: url("../assets/img/nsc2024-logo.png");
}

/* List Styles */
.nsc-list {
  list-style: disc;
  padding-left: 20px;
  border: #00000095 1px solid;
  border-radius: 5%;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.093);
  color: rgb(0, 0, 0);
}

.nsc-list li {
  margin-bottom: 0.5rem;
  list-style: none;
  padding-left: 20px;
}

/* Responsive Queries */
@media only screen and (max-width: 320px) {
  .option-button {
    /* background-image: none; */
    padding: 8px 16px;
    font-size: 14px;
  }

  .option-icon {
    width: 30px;
    height: 30px;
  }

  .option-textbox {
    padding: 8px;
  }

  .option-text {
    font-size: 14px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 360px) {
  .option-button {
    padding: 9px 18px;
    font-size: 15px;
  }

  .option-icon {
    /* display: none; */
  }

  .option-textbox {
    padding: 9px;
  }

  .option-text {
    font-size: 15px;
  }
}

@media only screen and (min-width: 361px) and (max-width: 375px) {
  .btn-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 0.4rem;
    justify-content: center;
    width: 100%;
  }

  .option-button {
    padding: 11px;
    height: 100px;
    font-size: 18px;
    border-radius: 0%;
  }

  .nscLogo {
    /* display: none; */
    width: 20px;
  }

  .option-icon {
    display: none;
  }

  .option-button:hover {
    transform: scale(1.01);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .option-text-box {
    margin: 0px;
    height: 80px;
  }

  .brokerages-button,
  .nsc2024-button,
  .agents-button,
  .conferences-button {
    margin: 0px;
  }

  .option-text h2 {
    font-size: 12px;
    font-weight: 700;
  }

  .option-text p {
    font-size: 9px;
  }

  #content {
    margin-top: 25px;
  }

  #hero {
    margin-top: 10px;
    padding-top: 1rem;
  }
}

@media only screen and (min-width: 376px) and (max-width: 414px) {
  .btn-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 0.4rem;
    justify-content: center;
    width: 100%;
  }

  .option-button {
    padding: 11px;
    height: 100px;
    font-size: 18px;
    border-radius: 0%;
  }

  .nscLogo {
    /* display: none; */
    width: 20px;
  }

  .option-icon {
    display: none;
  }

  .option-button:hover {
    transform: scale(1.01);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .option-text-box {
    margin: 0px;
    height: 80px;
  }

  .brokerages-button,
  .nsc2024-button,
  .agents-button,
  .conferences-button {
    margin: 0px;
  }

  .option-text h2 {
    font-size: 12px;
    font-weight: 700;
  }

  .option-text p {
    font-size: 9px;
  }

  #content {
    margin-top: 25px;
  }

  #hero {
    margin-top: 10px;
    padding-top: 1rem;
  }
}

@media only screen and (min-width: 415px) and (max-width: 480px) {
  .btn-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.4rem;
    justify-content: center;
  }

  .option-button {
    height: 150px;
    font-size: 1rem;
  }

  .nscLogo {
    /* display: none; */
    width: 30px;
  }

  .option-icon {
    font-size: 2rem;
  }

  .option-text-box {
    height: 100px;
  }

  .option-text h2 {
    font-size: 14px;
    font-weight: 700;
  }

  .option-text p {
    font-size: 11px;
  }
}

@media only screen and (min-width: 481px) {
  .btn-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 1rem;
    justify-content: center;
  }

  .option-button {
    height: 200px;
    font-size: 1.2rem;
    padding: 1rem;
  }

  .nscLogo {
    width: 30px;
  }

  .option-text-box {
    height: 120px;
  }

  .option-text h2 {
    font-size: 16px;
    font-weight: 700;
  }

  .option-text p {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1024px) {
  .btn-group {
    grid-template-columns: repeat(4, 1fr);
    gap: 0.75rem;
  }

  .option-button {
    height: 250px;
    font-size: 1.5rem;
    padding: 1.5rem;
  }

  .nscLogo {
    width: 40px;
  }

  .option-text-box {
    height: 150px;
    width: 150px;
  }

  .option-text h2 {
    font-size: 17px;
    font-weight: 700;
  }

  .option-text p {
    font-size: 12px;
  }
}

.qr-code-container {
  position: fixed;
  top: 80px;
  right: 20px;
  background: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.qr-code-container svg {
  display: block;
}

.btn-group-responsive {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.btn-group-responsive .option-button {
  flex: 1 1 calc(50% - 10px);
  margin: 5px;
}

@media (max-width: 450px) {
  .btn-group-responsive {
    flex-direction: column;
  }

  .btn-group-responsive .option-button {
    flex: 1 1 100%;
    margin: 5px 0;
  }
}
