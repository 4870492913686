.conferences_bg__hero {
  background-image: url("../assets/img/hero-conference.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  color: #fff;
  position: relative; /* Required for pseudo-element positioning */
}

.conferences_bg__hero:after {
  content: "";
  display: block;
  position: absolute; /* Position absolute to overlay on the hero section */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    34,
    139,
    230,
    0.76
  ); /* Adjusted color with transparency */
  z-index: 1; /* Ensure it's above the hero background but below text */
}

.conferences_bg__hero .row {
  position: relative; /* Ensures the text is above the pseudo-element */
  z-index: 2; /* Higher than the pseudo-element */
}
